import React from "react";
import "./profile.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Profile from "../../components/Profile/MainProfileComponent";

const Home = () => {
  return (
    <div>
      <div className="homeSubContainer">
        <div className="homeSidebar">
          <Sidebar />
        </div>
        <div className="profileRightbar">
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default Home;
