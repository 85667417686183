// cancelPage.js
import React from 'react';

const cancelPage = () => {
  return (
    <div>
      <h1>Payment cancel!</h1>
      <p>Try again</p>
      {/* You can add more details or a link to go back to the main page */}
    </div>
  );
};

export default cancelPage;
