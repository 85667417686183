import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRegisterMutation, useVerifyEmailMutation, useGoogleAuthMutation } from '../../../Shared/redux/userSlices/userSlice';
import { setCredentials } from '../../../Shared/redux/userSlices/authSlice';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import "./Signup.css";
import instagramLogo from "../../Icons/instagram_black.png";
import { toast } from 'react-toastify';

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailId, setemailId] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [error, setError] = useState('');
  const { emailToken } = useParams();
  // workSpace
  const [googleAuth] = useGoogleAuthMutation();
  const responseGoogle = (response) => {
    console.log("Client_ID_responseGoogle", response);
    if (response && response.profileObj) {
      console.log("Dispatching googleAuth action with profileObj:", response.profileObj);
      dispatch(googleAuth(response.profileObj));
    }
  };
  // workSpace

  const [register, { isLoading }] = useRegisterMutation();
  const [verifyEmail] = useVerifyEmailMutation();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    const verifyEmailToken = async () => {
      try {
        if (emailToken) {
          setVerificationStatus(true);
          const response = await verifyEmail({ emailToken }).unwrap();
          setVerificationStatus(false);
          dispatch(setCredentials(response));
          navigate('/home');
        }
      } catch (error) {
        console.error("Error verifying email:", error);
        setError("Error verifying email");
      }
    };

    if (!userInfo?.isVerified) {
      verifyEmailToken();
    }
  }, [emailToken, userInfo, verifyEmail, dispatch, navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      if(username && emailId && password){ 
      }
      const res = await register({ username, emailId, password }).unwrap();
      console.log("res",res)
      setVerificationStatus(res)
    } catch (err) {
      const errorMessage = err?.data?.error || 'An error occurred';
      setError(errorMessage);
      toast.error(errorMessage);
      console.log(errorMessage)
    }
  };

  const loginHandle = async (e) => {
    e.preventDefault();
    navigate("/")
  }

  return (
    <div className='mainSignupPage' style={{ }}>
      <div style={{marginTop: "4%", filter: verificationStatus ? 'blur(5px)' : 'none'  }}>
        <div className='signupRight'>
          <img src={instagramLogo} className='instagramLogo' alt="" />
          <p style={{ fontWeight: 600, fontSize: 18, marginTop: -15, marginLeft: 10, textAlign: "center", color: "#737373" }}>
            Sign up to see photos and videos from your friends.
          </p>

          <div style={{ marginLeft: 20 }}>
            <input
              type="text"
              placeholder='Email Id'
              className='inputSignUpPage'
              value={emailId}
              onChange={(e) => setemailId(e.target.value)}
            />
            <input
              type="text"
              placeholder='Username'
              className='inputSignUpPage'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder='Password'
              className='inputSignUpPage'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

          <p style={{ width: "90%", color: "#A8A8A8", fontSize: 13, marginLeft: 10, textAlign: 'center' }}>
            People who use our service may have uploaded your contact information to ConnectLoom
          </p>

          <button
            className='LoginButton'
            onClick={handleSignup}
            disabled={isLoading}
          >
            {isLoading ? 'Signing Up...' : 'Sign Up'}
          </button>

        </div>
        <div className="rightBottomSignupSection">
          <p style={{ color: "#000", marginLeft: 60 }}>
            Already have an account?
          </p>
          <Link to="/signup">
            <p onClick={loginHandle} style={{ color: "#00376B", cursor: 'pointer' }}>Login</p>
          </Link>
        </div>
      </div>
      {/* workspace */}
      {/* <div>
        {verificationStatus ? (
          <p>It is loading</p>
        ) : (
          <div>
            {userInfo?.isVerified ? (
              <Link to='/home'>Click next</Link>
            ) : (
              <div>
                {error ? (
                  <p>{error}</p>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div> */}
       {verificationStatus && (
        <div className="success-message-container">
          <div className="success-message">
            <p>Verification Mail sent successfully!</p>
            <p style={{color:'BLack',fontSize:12,marginBottom:5}}>Check inbox or Spam box</p>
          </div> 
        </div> 
      )}
    </div>
  );
}

export default Signup;
